const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: "year", ms: 24 * 60 * 60 * 1000 * 365 },
  { unit: "month", ms: (24 * 60 * 60 * 1000 * 365) / 12 },
  { unit: "day", ms: 24 * 60 * 60 * 1000 },
  { unit: "hour", ms: 60 * 60 * 1000 },
  { unit: "minute", ms: 60 * 1000 },
  { unit: "second", ms: 1000 },
];

export const useTimeAgo = (date: string | null) => {
  const { locale } = useI18n();

  const rtf = new Intl.RelativeTimeFormat(locale.value, {
    numeric: "auto",
    style: "short",
  });

  const relativeTimeFromElapsed = (elapsed: number) => {
    for (const { unit, ms } of units) {
      if (Math.abs(elapsed) >= ms || unit === "second") {
        return rtf.format(Math.round(elapsed / ms), unit);
      }
    }
    return "";
  };

  const relativeTimeFromDates = (
    relative: Date | null,
    pivot: Date = new Date(),
  ) => {
    if (!relative) return "";
    const elapsed = relative.getTime() - pivot.getTime();
    return relativeTimeFromElapsed(elapsed);
  };

  const timeAgo = computed(() => relativeTimeFromDates(new Date(date)));
  return timeAgo;
};
